<!--
 * @Author: 候怀烨
 * @Date: 2020-12-08 16:05:01
 * @LastEditTime: 2020-12-09 16:14:57
 * @LastEditors: Please set LastEditors
 * @Description: 查看活动详情
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\ViewDetails.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="预存款活动"
      :visible.sync="dialogVisible"
      width="70%"
      center
    >
      <div>
        <!-- 表单条件选择框 -->
        <el-form ref="form" :model="form" label-width="90px" size="mini">
          <el-form-item label="活动名称：">
            <el-input
              v-model="form.name"
              disabled
              placeholder="请输入活动名称"
              style="width: 320px"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动时间：">
            <el-date-picker
              v-model="form.join_start_at"
              type="date"
              disabled
              placeholder="选择开始时间"
              style="width: 130px"
            ></el-date-picker>
            <span style="padding: 0px 10px">至</span>
            <el-date-picker
              v-model="form.join_end_at"
              type="date"
              disabled
              placeholder="选择结束时间"
              style="width: 130px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="活动说明：">
            <el-input
              v-model="form.desc"
              type="textarea"
              disabled
              :rows="4"
              placeholder="请输入内容"
              style="width: 320px"
            ></el-input>
          </el-form-item>
          <el-form-item label="限定业务员">
            <span
              style="
                padding: 0px 10px;
                text-decoration: underline;
                cursor: pointer;
              "
              @click="xianyw(form.limit_users)"
            >
              {{ userLength }}
            </span>
          </el-form-item>
        </el-form>
        <span>活动方案：</span>
        <el-table stripe border style="width: 75%" :data="form.sche_list">
          <el-table-column
            align="center"
            label="方案"
            width="auto"
            type="index"
          ></el-table-column>
          <el-table-column
            align="center"
            label="预存金额"
            width="auto"
            prop="form.sche_list.total_amount"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.total_amount"
                placeholder="请输入预存金额"
                disabled
                @input="addrow(scope.$index)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="赠送金额"
            width="auto"
            prop="form.sche_list.gift_amount"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.gift_amount"
                disabled
                placeholder="请输入赠送金额"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="协议赠品"
            width="auto"
            prop="form.sche_list.give_data"
          >
            <template slot-scope="scope">
              <span
                style="
                  padding: 0px 10px;
                  text-decoration: underline;
                  cursor: pointer;
                "
                @click="zpc(form.sche_list[scope.$index].give_data)"
              >
                {{ form.sche_list[scope.$index].give_data.length }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="限定商品"
            width="auto"
            prop="form.sche_list.goods_data"
          >
            <template slot-scope="scope">
              <span
                style="
                  padding: 0px 10px;
                  text-decoration: underline;
                  cursor: pointer;
                "
                @click="
                  shangpin(
                    form.sche_list[scope.$index].goods_data,
                    form.sche_list[scope.$index].brand_data
                  )
                "
              >
                {{ form.sche_list[scope.$index].goods_data.length }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-col :span="4">
            <el-checkbox-group v-model="checkedCities">
              <el-checkbox
                v-for="city in cities"
                :key="city.id"
                disabled
                :label="city.id"
              >
                {{ city.name }}
              </el-checkbox>
            </el-checkbox-group>
            <el-radio v-model="form.check_status" disabled label="1">
              必须审核：所有预存款协议，必须后台审核后方可生效
            </el-radio>
            <el-radio v-model="form.check_status" disabled label="0">
              异常审核：勾选后，业务员修改协议内容，需后台审核后方可生效
            </el-radio>
          </el-col>
        </el-row>
        <el-row>
          <span>赠品赠送条件：</span>
          <el-radio-group v-model="form.gift_goods_type">
            <el-radio
              v-for="city in citiesing"
              :key="city.id"
              disabled
              :label="city.id"
            >
              {{ city.name }}
            </el-radio>
          </el-radio-group>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">保 存</el-button> -->
        <el-button @click="createtabl">关 闭</el-button>
      </span>
    </el-dialog>
    <collection-superclass ref="superclass"></collection-superclass>
  </div>
</template>

<script>
  import CollectionSuperclass from './CollectionSuperclass.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      CollectionSuperclass,
    },
    data() {
      return {
        dialogVisible: false,
        userLength: 0,
        form: {
          name: '',
          join_start_at: '',
          join_end_at: '',
          desc: '',
          limit_users: [],
          sche_list: [
            // {
            //   total_amount: '',
            //   gift_amount: '',
            //   goods_data: [],
            //   give_data: [],
            //   brand_data: [],
            // },
          ],
          repeat_status: '',
          price_status: '',
          exceed_status: '',
          gift_status: '',
          gift_goods_type: '',
          check_status: '',
        },
        checkedCities: [],
        citiesing: [
          { name: '签约即送', id: '1' },
          { name: '全额收款后赠送', id: '2' },
          { name: '消费完后赠品', id: '3' },
        ],
        cities: [
          { name: '活动可重复参加', id: '1' },
          { name: '下单时业务员可修改价格', id: '2' },
          {
            name: '允许超额下单：勾选后，单据可用金额可以是负数，客户可超出已付款下单，超出部分计入待收款金额',
            id: '3',
          },
          // { name: '允许使用预存款购买常规赠品', id: '4' },
        ],
        url: {
          xingqing: '/promoteAdmin/deposit/detail',
        },
      }
    },
    watch: {
      dialogVisible(v) {
        if (!v) {
          this.checkedCities = []
        }
      },
    },
    methods: {
      shangpin(row, dato) {
        this.$refs.superclass.sp = true
        this.$refs.superclass.spdata = row
        this.$refs.superclass.treelist = dato
        console.log(this.$refs.superclass.spdata)
      },
      zpc(row) {
        this.$refs.superclass.zp = true
        this.$refs.superclass.zpdata = row
        console.log(this.$refs.superclass.zpdata)
      },
      xianyw(row) {
        row = row.split(',')
        console.log(row)
        if (row[0] != '') {
          this.$refs.superclass.ywy = true
          this.$refs.superclass.hanlderdataywy(row)
        } else {
          this.$message({
            message: '没有相关联的业务员',
            type: 'warning',
          })
        }
      },
      handlerData(row) {
        postAction(this.url.xingqing, { id: row }).then((res) => {
          this.form = res.data
          // this.form.limit_users = res.data.limit_users.split(',')
          this.userLength =
            res.data.limit_users == ''
              ? 0
              : res.data.limit_users.split(',').length
          console.log(res.data.limit_users.split(',').length)
          console.log(this.userLength)

          console.log(
            Object.prototype.toString.call(this.form.limit_users),
            '打印'
          )
          if (this.form.repeat_status == 1) {
            this.checkedCities.push('1')
          }
          if (this.form.price_status == 1) {
            this.checkedCities.push('2')
          }
          if (this.form.exceed_status == 1) {
            this.checkedCities.push('3')
          }
          if (this.form.gift_status == 1) {
            this.checkedCities.push('4')
          }
        })
        // .catch((err) => {
        //   console.log('err', '')
        // })
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
      createtabl() {
        this.dialogVisible = false
      },
    },
  }
</script>

<style></style>
