var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              _vm.dialogVisible = true
            },
          },
        },
        [_vm._v("添加商品")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "添加商品",
            visible: _vm.dialogVisible,
            width: "50%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form, inline: "" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "请选择品类" },
                          model: {
                            value: _vm.form.class_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "class_id", $$v)
                            },
                            expression: "form.class_id",
                          },
                        },
                        _vm._l(_vm.lei, function (list, indexs) {
                          return _c("el-option", {
                            key: indexs,
                            attrs: { label: list.class_name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "120px" },
                        attrs: {
                          options: _vm.pai,
                          props: {
                            checkStrictly: true,
                            value: "id",
                            label: "brand_name",
                            children: "children",
                          },
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.brand_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "brand_id", $$v)
                          },
                          expression: "form.brand_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商品名称简拼" },
                        model: {
                          value: _vm.form.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "keyword", $$v)
                          },
                          expression: "form.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.chauxun },
                        },
                        [_vm._v("查 询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  ref: "tables",
                  attrs: {
                    stripe: "",
                    data: _vm.shangpinlist,
                    border: "",
                    "row-key": _vm.getRowKey,
                    "max-height": "350",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      align: "center",
                      "reserve-selection": true,
                    },
                  }),
                  _vm._l(_vm.clomd, function (list, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: list.prop,
                        label: list.label,
                        align: list.align,
                        width: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          list.label == "下单价"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      model: {
                                        value: row.sell_price_,
                                        callback: function ($$v) {
                                          _vm.$set(row, "sell_price_", $$v)
                                        },
                                        expression: "row.sell_price_",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(" " + _vm._s(row[list.prop]) + " "),
                                  ]
                                },
                              },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                ],
                2
              ),
              _c("el-pagination", {
                attrs: { layout: "prev, pager, next", total: _vm.totalCount },
                on: { "current-change": _vm.handleCurrentChange },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.tianjia } },
                [_vm._v("添 加")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }