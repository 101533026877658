<!--
 * @Author: 候怀烨
 * @Date: 2020-11-30 16:53:24
 * @LastEditTime: 2021-04-07 16:39:10
 * @LastEditors: Please set LastEditors
 * @Description: 创建新预存款活动
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\ActivityDing.vue
-->
<template>
  <div>
    <el-button type="primary" @click="dialogVisible = true">
      创建新活动
    </el-button>

    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="创建预存款活动"
      :visible.sync="dialogVisible"
      width="70%"
      center
    >
      <div>
        <!-- 表单条件选择框 -->
        <el-form ref="form" :model="form" label-width="90px" size="mini">
          <el-form-item label="活动名称：">
            <el-input
              v-model="form.name"
              placeholder="请输入活动名称"
              style="width: 320px"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动时间：">
            <el-date-picker
              v-model="form.join_start_at"
              type="date"
              placeholder="选择开始时间"
              style="width: 180px"
            ></el-date-picker>
            <span style="padding: 0px 10px">至</span>
            <el-date-picker
              v-model="form.join_end_at"
              type="date"
              placeholder="选择结束时间"
              style="width: 180px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="活动说明：">
            <el-input
              v-model="form.desc"
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              style="width: 320px"
            ></el-input>
          </el-form-item>
          <el-form-item label="限定业务员">
            <span style="padding: 0px 10px">{{ limit_users.length }}</span>
            <add-employee @set-userid="setUserid"></add-employee>
          </el-form-item>
        </el-form>
        <span>活动方案：</span>
        <el-table stripe border style="width: 75%" :data="form.sche_list">
          <el-table-column
            align="center"
            label="方案"
            width="auto"
            type="index"
          ></el-table-column>
          <el-table-column
            align="center"
            label="预存金额"
            width="auto"
            prop="form.sche_list.total_amount"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.total_amount"
                placeholder="请输入预存金额"
                @input="addrow(scope.$index)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="赠送金额"
            width="auto"
            prop="form.sche_list.gift_amount"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.gift_amount"
                placeholder="请输入赠送金额"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="协议赠品"
            width="auto"
            prop="form.sche_list.give_data"
          >
            <template slot-scope="scope">
              {{ form.sche_list[scope.$index].give_data.length }}
              <giveaway-ding
                v-if="dialogVisible"
                :index="scope.$index"
                :goods-list="form.sche_list[scope.$index].give_data"
                @xieyi="xieyi"
              ></giveaway-ding>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="限定商品"
            width="auto"
            prop="form.sche_list.goods_data"
          >
            <template slot-scope="scope">
              <span>{{ form.sche_list[scope.$index].goods_data.length }}</span>
              <limited
                v-if="dialogVisible"
                ref="limited"
                :goods-list="form.sche_list[scope.$index].goods_data"
                :brand-list="form.sche_list[scope.$index].brand_data"
                :index="scope.$index"
                @xianding="xianding"
              ></limited>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="auto">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="addhuodong(scope.$index, scope.row)"
              >
                添加
              </el-button>
              <el-button type="text" @click="deletes(scope.$index, scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-col :span="4">
            <el-checkbox-group v-model="checkedCities" @change="Cities">
              <el-checkbox
                v-for="city in cities"
                :key="city.id"
                :label="city.id"
              >
                {{ city.name }}
              </el-checkbox>
            </el-checkbox-group>
            <el-radio v-model="form.check_status" label="1">
              必须审核：所有预存款协议，必须后台审核后方可生效
            </el-radio>
            <el-radio v-model="form.check_status" label="0">
              异常审核：勾选后，业务员修改协议内容，需后台审核后方可生效
            </el-radio>
          </el-col>
        </el-row>
        <el-row>
          <span>赠品赠送条件：</span>
          <el-radio-group v-model="form.gift_goods_type">
            <el-radio v-for="city in citiesing" :key="city.id" :label="city.id">
              {{ city.name }}
            </el-radio>
          </el-radio-group>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createtabl">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import AddEmployee from './AddEmployee.vue'
  import GiveawayDing from './GiveawayDing.vue'
  import limited from './limited'
  export default {
    components: {
      AddEmployee,
      GiveawayDing,
      limited,
    },
    data() {
      return {
        dialogVisible: false,
        limit_users: [],
        form: {
          name: '',
          join_start_at: '',
          join_end_at: '',
          desc: '',
          limit_users: '',
          sche_list: [
            {
              total_amount: '',
              gift_amount: '',
              goods_data: [],
              give_data: [],
              brand_data: [],
            },
          ],
          repeat_status: 0,
          price_status: 0,
          exceed_status: 0,
          gift_status: 0,
          gift_goods_type: '1',
          check_status: '1',
        },
        checkedCities: ['2'],
        citiesing: [
          { name: '签约即送', id: '1' },
          { name: '全额收款后赠送', id: '2' },
          { name: '消费完后赠品', id: '3' },
        ],
        cities: [
          { name: '活动可重复参加', id: '1' },
          { name: '下单时业务员可修改价格', id: '2' },
          {
            name: '允许超额下单：勾选后，单据可用金额可以是负数，客户可超出已付款下单，超出部分计入待收款金额',
            id: '3',
          },
          // { name: '允许使用预存款购买常规赠品', id: '4' },
        ],
        url: {
          yugong: '/baseAdmin/common/user-list',
          created: '/promoteAdmin/deposit/create',
        },
      }
    },
    watch: {
      dialogVisible(val) {
        if (!val) {
          this.checkedCities = ['2']
          this.limit_users = []
          this.form = {
            name: '',
            join_start_at: '',
            join_end_at: '',
            desc: '',
            limit_users: '',
            sche_list: [
              {
                total_amount: '',
                gift_amount: '',
                goods_data: [],
                give_data: [],
                brand_data: [],
              },
            ],
            repeat_status: 0,
            price_status: 0,
            exceed_status: 0,
            gift_status: 0,
            gift_goods_type: '1',
            check_status: '1',
          }
          this.clearSunData()
        }
      },
    },
    methods: {
      // 创建预存款活动
      createtabl() {
        var formlist = []
        formlist = JSON.parse(JSON.stringify(this.form))
        console.log(formlist, '方案')
        var data = []
        data = formlist.sche_list.filter((list) => {
          return list.total_amount != ''
        })
        if (formlist.sche_list.length > 0) {
          formlist.sche_list = data
          formlist.sche_list = JSON.stringify(formlist.sche_list)
          if (this.checkedCities.includes('2')) {
            formlist.price_status = 1
          } else {
            formlist.price_status = 0
          }
          console.log('formlist', formlist)
          postAction(this.url.created, formlist)
            .then((res) => {
              this.$emit('refresh')
              console.log(res, '创建成功')
              this.$refs.limited.clearGoods()
              this.$message({
                message: res.msg,
                type: 'success',
              })

              this.dialogVisible = false
            })
            .catch((err) => {
              console.log(err, '创建失败')
            })
        } else {
          this.$message({
            message: '请完善方案',
            type: 'error',
          })
        }
      },
      Cities(val) {
        console.log(val, '')
        val.forEach((itm) => {
          console.log(itm, '')
          if (itm == 1) {
            this.form.repeat_status = 1
          } else if (itm == 2) {
            this.form.price_status = 1
          } else if (itm == 3) {
            this.form.exceed_status = 1
          } else if (itm == 4) {
            this.form.gift_status = 1
          } else {
            this.form.repeat_status = 0
            this.form.price_status = 0
            this.form.exceed_status = 0
            this.form.gift_status = 0
          }
        })
      },
      xieyi(list, index) {
        console.log(list, index)
        try {
          var d = []
          list.forEach((itme) => {
            let z = {
              goods_id: itme.goods_id,
              goods_name: itme.goods_name,
              goods_num: itme.goods_num,
              goods_specs: itme.specs,
              brand_name: itme.brand_name,
              brand_id: itme.brand_id,
              unit_id: itme.unit_id,
              unit_name: itme.unit_name,
            }
            d.push(z)
          })
          this.form.sche_list[index].give_data = d
        } catch (error) {
          console.log(error, '异常捕获')
        }
      },
      xianding(goods, list, index) {
        console.log(goods, list, index)
        var b = []
        var a = []
        list.forEach((lit) => {
          let s = { brand_name: lit.brand_name, brand_id: lit.id }
          a.push(s)
        })
        this.form.sche_list[index].brand_data = a
        goods.forEach((item) => {
          item.goods_specs = item.specs
          item.unit_name = item.goods_unit
          item.goods_price = item.sell_price
          item.back_price = item.sell_price

          // let a = {
          //   goods_id: item.goods_id,
          //   goods_name: item.goods_name,
          //   goods_specs: item.specs,
          //   brand_name: item.brand_name,
          //   brand_id: item.brand_id,
          //   unit_id: item.unit_id,
          //   unit_name: item.goods_unit,
          //   goods_price: item.sell_price,
          //   // sell_price: item.sell_price,
          //   back_price: item.sell_price,
          // }
          b.push(item)
        })
        this.form.sche_list[index].goods_data = b
      },
      indeex(index) {
        console.log(index, '')
      },
      addrow(index) {
        if (this.form.sche_list.length == index + 1) {
          this.form.sche_list.push({
            total_amount: '',
            gift_amount: '',
            goods_data: [],
            give_data: [],
          })
        }
      },
      clearSunData() {
        console.log('清空')
        this.$refs.limited.clearGoods()
      },
      setUserid(userid) {
        console.log(userid, '用户id')
        this.form.limit_users = userid.join(',')
        this.limit_users = userid
      },
      deletes(index, row) {
        console.log(this.form.sche_list.length, '')
        if (index == this.form.sche_list.length - 1) {
          this.$message({
            message: '警告，您不能删除最后一条信息',
            type: 'warning',
          })
        } else {
          this.form.sche_list.splice(index, 1)
        }
      },
      addhuodong(index, row) {
        this.form.sche_list.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
    },
  }
</script>

<style></style>
