<!--
 * @Author: 候怀烨
 * @Date: 2020-12-03 20:04:39
 * @LastEditTime: 2020-12-04 18:31:03
 * @LastEditors: Please set LastEditors
 * @Description: 添加品牌
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\AddBrand.vue
-->
<template>
  <div>
    <el-button type="primary" @click="dialogVisible = true">添加品牌</el-button>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="dialogVisible"
      center
      width="30%"
    >
      <div style="height: 250px; overflow-y: auto">
        <el-tree
          ref="tree"
          :data="treedata"
          show-checkbox
          default-expand-all
          :check-strictly="true"
          check-on-click-node
          node-key="id"
          highlight-current
          :props="defaultProps"
          @check-change="change"
        ></el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        dialogVisible: false,
        treedata: [],
        treeitme: [],
        defaultProps: {
          children: 'children',
          label: 'brand_name',
        },
        url: {
          pinpai: '/baseAdmin/common/goods-brand-option',
        },
      }
    },
    // props: {
    //   treelistkey: {
    //     type: String,
    //     default() {
    //       return ''
    //     },
    //   },
    // },
    watch: {
      dialogVisible(v) {
        if (!v) {
          this.clearBrand()
        }
      },
    },
    mounted() {
      this.handlerTree()
    },
    methods: {
      handlerchange(data) {
        console.log(data, 'hhy')
        this.$refs.tree.setChecked(data, false)
      },
      submit() {
        console.log('tag', '')
        this.$emit('treelit', this.treeitme)
        console.log(this.treeitme, 'lalal')
        this.dialogVisible = false
      },
      change() {
        let res = this.$refs.tree.getCheckedNodes()
        console.log(res, '')
        this.treeitme = res
      },
      handlerTree() {
        postAction(this.url.pinpai, {})
          .then((res) => {
            console.log(res)
            this.treedata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
      clearBrand() {
        let this_ = this
        this.treedata.forEach((i) => {
          this_.$nextTick(() => {
            this_.$refs.tree.setChecked(i.id, false)
          })
        })
        this.treeitme = []
      },
    },
  }
</script>
