var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("i", {
        staticClass: "el-icon-edit-outline",
        on: {
          click: function ($event) {
            _vm.dialogVisible = true
          },
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "限定商品",
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "space-between" },
                },
                [
                  _c("span", [_vm._v("限定品牌：")]),
                  _c("add-brand", {
                    ref: "tree",
                    on: { treelit: _vm.treelit },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { padding: "10px 0px" }, attrs: { gutter: 20 } },
                _vm._l(_vm.treelist, function (item, indexd) {
                  return _c(
                    "el-col",
                    { key: indexd, attrs: { span: 6 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "el-icon-close",
                          on: {
                            click: function ($event) {
                              return _vm.deletetree(_vm.index)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.brand_name) + " ")]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "space-between" },
                },
                [
                  _c("span", [_vm._v("限定商品：")]),
                  _c("adding-goods", {
                    ref: "goods",
                    on: { addgoos: _vm.addgoos },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        stripe: "",
                        data: _vm.addgoods,
                        "max-height": "300",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", width: "50" },
                      }),
                      _vm._l(_vm.comle, function (list, indexs) {
                        return _c("el-table-column", {
                          key: indexs,
                          attrs: {
                            prop: list.prop,
                            label: list.label,
                            align: list.align,
                            width: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              list.label == "下单价"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          model: {
                                            value: row.sell_price,
                                            callback: function ($$v) {
                                              _vm.$set(row, "sell_price", $$v)
                                            },
                                            expression: "row.sell_price",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          " " + _vm._s(row[list.prop]) + " "
                                        ),
                                      ]
                                    },
                                  },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          prop: "",
                          align: "center",
                          width: "auto",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var $index = ref.$index
                              var row = ref.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deletetab($index, row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删 除 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.xianding } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }