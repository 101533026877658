<!--
 * @Author: 候怀烨
 * @Date: 2020-12-03 14:21:23
 * @LastEditTime: 2020-12-07 16:42:49
 * @LastEditors: Please set LastEditors
 * @Description: 协议赠品
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\GiveawayDing.vue
-->
<template>
  <div>
    <!-- <span style="padding: 0px 10px">{{ tableData.length - 1 }}</span> -->
    <i class="el-icon-edit-outline" @click="dialogVisible = true"></i>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="协议赠品"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <div>
        <!-- 表格 -->
        <el-table
          ref="singleTable"
          stripe
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column prop="" label="商品名称" width="auto" align="center">
            <template #default="{ row, $index }">
              <template v-if="edit">
                <goods-search
                  :f-key="row.goods_name"
                  :search="true"
                  :show-kouwei="false"
                  @add-rows="addmany($event, row, $index)"
                  @select-goods-all="selectGoods($event, row)"
                ></goods-search>
              </template>
              <span v-else>{{ row.goods_name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="specs"
            label="规格"
            width="auto"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="unit_id"
            label="单位"
            width="auto"
            align="center"
          >
            <template #default="{ $index, row }">
              <template v-if="edit">
                <!-- <el-input v-model="row.type" style="width: 100px" /> -->
                <el-select
                  v-model="row.unit_id"
                  placeholder="选择单位"
                  style="width: 70px"
                  @change="unitChange($index, $event, row)"
                >
                  <el-option
                    v-for="item in row.arr_unit"
                    :key="item.id"
                    :label="item.unit_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
              <span v-else>{{ row.units }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="goods_num"
            label="数量"
            width="auto"
            align="center"
          >
            <template #default="{ row }">
              <template v-if="edit">
                <el-input v-model="row.goods_num" style="width: 80px" />
              </template>
              <span v-else>{{ row.goods_num }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="auto" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="copy(scope.$index, scope.row)">
                复制
              </el-button>
              <el-button type="text" @click="deleter(scope.$index, scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="xieyi">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getInputList } from '@/api/goodsSearchInput'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    components: { GoodsSearch },
    props: {
      index: {
        type: Number,
        default() {
          return 0
        },
      },
      goodsList: {
        type: [Array, Object],
        default: () => {},
      },
    },
    data() {
      return {
        dialogVisible: false,
        tableData: [
          {
            goods_id: '',
            goods_name: '',
            goods_specs: '',
            brand_name: '',
            brand_id: '',
            unit_id: '',
            unit_name: '',
            goods_price: '',
            back_price: '',
            goods_num: '',
            arr_unit: [],
          },
        ],
        edit: true,
      }
    },
    watch: {
      dialogVisible(val) {
        if (!val) {
          // this.xieyi()
        } else {
          this.tableData = JSON.parse(JSON.stringify(this.goodsList))
          this.tableData.push({
            goods_id: '',
            goods_name: '',
            goods_specs: '',
            brand_name: '',
            brand_id: '',
            unit_id: '',
            unit_name: '',
            goods_price: '',
            back_price: '',
            goods_num: '',
            arr_unit: [],
          })
        }
      },
    },
    methods: {
      xieyi() {
        // var a = this.tableData
        // let b = this.tableData.length - 1
        // a.splice(b, 1)
        // console.log(a, '')
        let data = []
        this.tableData.forEach((i) => {
          if (i.goods_id != '') {
            data.push(i)
          }
        })
        this.$emit('xieyi', data, this.index)
        this.dialogVisible = false
      },
      // 表格内单位切换
      unitChange(index, e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        row.goods_price = price
        let unitname = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        console.log(unitname, '单位')
        row.unit_name = unitname
      },
      // 商品搜索下拉
      async fetchData(ky = '') {
        let { data, code, msg } = await getInputList({
          pageNo: 1,
          pageSize: -1,
        })
        console.log(data)
        if (code == 200) {
          return data
        } else {
          this.$message.error(msg)
        }
      },
      copy(index, row) {
        console.log(index, row, '什么')
        let rowS = JSON.parse(JSON.stringify(row))
        // let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        this.tableData.splice(index, 0, rowS)
      },
      deleter(index, row) {
        if (this.tableData.length == 1) {
          // alert('不能删除最后一行')
          this.$message.error('不能删除最后一行')
          return
        }
        this.tableData.splice(index, 1)
      },
      // 添加行
      addRow(index, row, $event) {
        console.log(index, $event)
        if (this.tableData.length == index + 1) {
          this.tableData.push({
            goods_id: '',
            goods_name: '',
            goods_specs: '',
            brand_name: '',
            brand_id: '',
            unit_id: '',
            unit_name: '',
            goods_price: '',
            back_price: '',
            goods_num: '',
            arr_unit: [],
          })
        }
      },
      handleSelect(index, row, $event) {
        console.log(index, row, $event, '看看是什么')
        this.tableData[index] = $event
        // this.list[index].arr_unit = $event.arr_unit
        // this.list[index].arr_goods_type = $event.arr_goods_type
        this.tableData[index].goods_type = 1
        this.tableData[index].unit_id = ''
        // this.list[index].unit_name = '0'
        console.log(this.tableData)
      },
      // 表格内商品名称输入框搜索
      // querySearch2(queryString, cb) {
      //   this.fetchData(queryString).then((val) => {
      //     console.log(val)
      //     cb(val)
      //   })
      // },
      // 选择商品下拉
      selectGoods(val, row) {
        console.log(val, row)
        if (val.goods_id != -1) {
          console.log('val', val, row)
          Object.assign(row, val)
          row.unit_name = val.arr_unit.filter(
            (list) => list.unit_id == val.unit_id
          )[0].unit_name
          this.tableData.push({
            goods_id: '',
            goods_name: '',
            goods_specs: '',
            brand_name: '',
            brand_id: '',
            unit_id: '',
            unit_name: '',
            goods_price: '',
            back_price: '',
            goods_num: '',
            arr_unit: [],
          })
        }
      },
      //新加批量
      addmany(val, row, index) {
        console.log(val, row, index)
        if (val.length > 0) {
          this.tableData.splice(index, 0, ...val)
        }
      },
    },
  }
</script>

<style></style>
