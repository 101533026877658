<!--
 * @Author: 候怀烨
 * @Date: 2020-11-30 10:59:32
 * @LastEditTime: 2020-12-10 10:49:42
 * @LastEditors: Please set LastEditors
 * @Description: 预存款活动
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\index.vue
-->
<template>
  <!-- NAME[epic=动销] 预存款活动 -->
  <div class="padding">
    <!--    <activity-heard ref="heard"></activity-heard>-->
    <!--    <activity-table ref="table" @tabl="tables"></activity-table>-->
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="550">
        <el-form ref="form" :model="form" inline>
          <el-form-item>
            <el-date-picker
              v-model="form.start_time"
              type="date"
              placeholder="开始时间"
              style="width: 130px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="form.end_time"
              type="date"
              placeholder="结束时间"
              style="width: 130px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.name"
              placeholder="请输入活动名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.activity_status"
              style="width: 130px"
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in zhuaangtai"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">查 询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2">
        <activity-ding @refresh="handlerData"></activity-ding>
      </el-col>
    </el-row>
    <!-- 表格 -->
    <el-table
      stripe
      border
      :data="listtable"
      show-summary
      :summary-method="summaryFunction"
    >
      <el-table-column
        align="center"
        label="顺序"
        type="index"
        width="50"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column
        v-for="item in colme"
        :key="item.id"
        :align="item.align"
        :label="item.label"
        :prop="item.prop"
        width=""
      ></el-table-column>
      <!--      <el-table-column align="center" label="状态" prop="status" width="80">
        <template slot-scope="scope">
          <span v-show="scope.row.activity_status == 1">正常</span>
          <span v-show="scope.row.activity_status == 2">停止</span>
          <span v-show="scope.row.activity_status == 3">未开始</span>
          <span v-show="scope.row.activity_status == 4">已结束</span>
        </template>
      </el-table-column>-->
      <el-table-column align="center" label="操作" fixed="right" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="link(scope.row)">查看</el-button>
          <el-button type="text" @click="handleredit(scope.row)">
            修改
          </el-button>
          <el-button type="text" @click="handlercopy(scope.row)">
            复制
          </el-button>
          <el-button
            v-show="scope.row.status == 1"
            type="text"
            @click="ting(scope.row)"
          >
            停止
          </el-button>
          <el-button
            v-show="scope.row.status == 0"
            type="text"
            @click="ting(scope.row)"
          >
            正常
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        :current-page="pageNo"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <view-details ref="view"></view-details>
    <edit ref="edit" @getlist="getlist"></edit>
  </div>
</template>

<script>
  // import ActivityHeard from './components/ActivityHeard.vue'
  // import ActivityTable from './components/ActivityTable.vue'
  import ViewDetails from './components/ViewDetails.vue'
  import ActivityDing from './components/ActivityDing.vue'
  import { postAction } from '@/api/Employee'

  export default {
    name: 'Depositactivity',
    components: {
      ViewDetails,
      ActivityDing,
      // ActivityHeard,
      // ActivityTable,
      edit: () => import('./components/edit'),
    },
    data() {
      return {
        form: {
          pageNo: 1,
          pageSize: 10,
          start_time: '',
          end_time: '',
          name: '',
          activity_status: 0,
        },
        url: {
          actionslist: '/promoteAdmin/deposit',
          gaizhuang: '/promoteAdmin/deposit/status',
          copy: '/promoteAdmin/deposit/copy',
        },
        zhuaangtai: [
          {
            id: 0,
            name: '全部状态',
          },
          {
            id: 1,
            name: '进行中',
          },
          {
            id: 2,
            name: '已停止',
          },
          {
            id: 3,
            name: '未开始',
          },
          {
            id: 4,
            name: '已结束',
          },
        ],
        listtable: [],
        colme: [
          {
            align: 'center',
            label: '活动ID',
            prop: 'id',
            width: '130',
          },
          {
            align: 'center',
            label: '活动名称',
            prop: 'name',
            width: '130',
          },
          {
            align: 'center',
            label: '活动说明',
            prop: 'desc',
            width: '220',
          },
          {
            align: 'center',
            label: '开始时间',
            prop: 'join_start_at',
            width: '130',
          },
          {
            align: 'center',
            label: '结束时间',
            prop: 'join_end_at',
            width: '130',
          },
          {
            align: 'center',
            label: '参与数量',
            prop: 'count',
            width: '80',
          },
          {
            align: 'center',
            label: '状态',
            prop: 'activity_text',
            width: '80',
          },
          {
            align: 'center',
            label: '收款金额',
            prop: 'receive_amount',
            width: '130',
          },
          {
            align: 'center',
            label: '签单金额',
            prop: 'total_amount',
            width: '130',
          },
        ],
        totalCount: 0,
        pageNo: 1,
      }
    },
    mounted() {
      this.submit()
    },
    methods: {
      /*
       * 修改
       * hhy
       * 2021/9/23
       * */
      handleredit(row) {
        this.$refs.edit.showedit = true
        this.$refs.edit.handlerinfo(row.id)
      },
      getlist() {
        this.submit()
      },
      submit() {
        this.form.pageNo = 1
        this.handlerData()
      },
      handlerData() {
        postAction(this.url.actionslist, this.form)
          .then((res) => {
            console.log(res, '列表成功')
            this.listtable = res.data
            this.totalCount = res.totalCount
          })
          .catch((err) => {
            console.log(err, '列表失败')
          })
      },
      /*
       * zhge
       * */
      handlercopy(row) {
        console.log(row)
        postAction(this.url.copy, { id: row.id })
          .then((res) => {
            console.log(res, '')
            this.$message(res.msg)
            this.submit()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      link(row) {
        console.log(row, '')
        this.$refs.view.dialogVisible = true
        this.$refs.view.handlerData(row.id)
      },
      // 停用，正常共同使用
      ting(row) {
        postAction(this.url.gaizhuang, { id: row.id })
          .then((res) => {
            console.log(res, '')
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.submit()
            }
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      indexMethod(index) {
        return index + 1
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerData()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerData()
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [6, 7, 8]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                if (idx == 6) {
                  sums[n] = a
                } else {
                  sums[n] = a.toFixed(2) + ' 元'
                }
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
    },
  }
</script>

<style>
  .padding {
    padding: 20px;
  }
</style>
