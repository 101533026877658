var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("i", {
        staticClass: "el-icon-edit-outline",
        on: {
          click: function ($event) {
            _vm.dialogVisible = true
          },
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "协议赠品",
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  ref: "singleTable",
                  staticStyle: { width: "100%" },
                  attrs: { stripe: "", data: _vm.tableData },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "商品名称",
                      width: "auto",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _vm.edit
                              ? [
                                  _c("goods-search", {
                                    attrs: {
                                      "f-key": row.goods_name,
                                      search: true,
                                      "show-kouwei": false,
                                    },
                                    on: {
                                      "add-rows": function ($event) {
                                        return _vm.addmany($event, row, $index)
                                      },
                                      "select-goods-all": function ($event) {
                                        return _vm.selectGoods($event, row)
                                      },
                                    },
                                  }),
                                ]
                              : _c("span", [_vm._v(_vm._s(row.goods_name))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "specs",
                      label: "规格",
                      width: "auto",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "unit_id",
                      label: "单位",
                      width: "auto",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _vm.edit
                              ? [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "70px" },
                                      attrs: { placeholder: "选择单位" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.unitChange(
                                            $index,
                                            $event,
                                            row
                                          )
                                        },
                                      },
                                      model: {
                                        value: row.unit_id,
                                        callback: function ($$v) {
                                          _vm.$set(row, "unit_id", $$v)
                                        },
                                        expression: "row.unit_id",
                                      },
                                    },
                                    _vm._l(row.arr_unit, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.unit_name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              : _c("span", [_vm._v(_vm._s(row.units))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goods_num",
                      label: "数量",
                      width: "auto",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm.edit
                              ? [
                                  _c("el-input", {
                                    staticStyle: { width: "80px" },
                                    model: {
                                      value: row.goods_num,
                                      callback: function ($$v) {
                                        _vm.$set(row, "goods_num", $$v)
                                      },
                                      expression: "row.goods_num",
                                    },
                                  }),
                                ]
                              : _c("span", [_vm._v(_vm._s(row.goods_num))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "操作",
                      width: "auto",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(scope.$index, scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleter(scope.$index, scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.xieyi } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }