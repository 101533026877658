var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              _vm.dialogVisible = true
            },
          },
        },
        [_vm._v(" 创建新活动 ")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "创建预存款活动",
            visible: _vm.dialogVisible,
            width: "70%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "90px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "320px" },
                        attrs: { placeholder: "请输入活动名称" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动时间：" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "180px" },
                        attrs: { type: "date", placeholder: "选择开始时间" },
                        model: {
                          value: _vm.form.join_start_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "join_start_at", $$v)
                          },
                          expression: "form.join_start_at",
                        },
                      }),
                      _c("span", { staticStyle: { padding: "0px 10px" } }, [
                        _vm._v("至"),
                      ]),
                      _c("el-date-picker", {
                        staticStyle: { width: "180px" },
                        attrs: { type: "date", placeholder: "选择结束时间" },
                        model: {
                          value: _vm.form.join_end_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "join_end_at", $$v)
                          },
                          expression: "form.join_end_at",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动说明：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "320px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.form.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "desc", $$v)
                          },
                          expression: "form.desc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "限定业务员" } },
                    [
                      _c("span", { staticStyle: { padding: "0px 10px" } }, [
                        _vm._v(_vm._s(_vm.limit_users.length)),
                      ]),
                      _c("add-employee", {
                        on: { "set-userid": _vm.setUserid },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("span", [_vm._v("活动方案：")]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "75%" },
                  attrs: { stripe: "", border: "", data: _vm.form.sche_list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "方案",
                      width: "auto",
                      type: "index",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "预存金额",
                      width: "auto",
                      prop: "form.sche_list.total_amount",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: { placeholder: "请输入预存金额" },
                              on: {
                                input: function ($event) {
                                  return _vm.addrow(scope.$index)
                                },
                              },
                              model: {
                                value: scope.row.total_amount,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "total_amount", $$v)
                                },
                                expression: "scope.row.total_amount",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "赠送金额",
                      width: "auto",
                      prop: "form.sche_list.gift_amount",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: { placeholder: "请输入赠送金额" },
                              model: {
                                value: scope.row.gift_amount,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "gift_amount", $$v)
                                },
                                expression: "scope.row.gift_amount",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "协议赠品",
                      width: "auto",
                      prop: "form.sche_list.give_data",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.form.sche_list[scope.$index].give_data
                                    .length
                                ) +
                                " "
                            ),
                            _vm.dialogVisible
                              ? _c("giveaway-ding", {
                                  attrs: {
                                    index: scope.$index,
                                    "goods-list":
                                      _vm.form.sche_list[scope.$index]
                                        .give_data,
                                  },
                                  on: { xieyi: _vm.xieyi },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "限定商品",
                      width: "auto",
                      prop: "form.sche_list.goods_data",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.form.sche_list[scope.$index].goods_data
                                    .length
                                )
                              ),
                            ]),
                            _vm.dialogVisible
                              ? _c("limited", {
                                  ref: "limited",
                                  attrs: {
                                    "goods-list":
                                      _vm.form.sche_list[scope.$index]
                                        .goods_data,
                                    "brand-list":
                                      _vm.form.sche_list[scope.$index]
                                        .brand_data,
                                    index: scope.$index,
                                  },
                                  on: { xianding: _vm.xianding },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作", width: "auto" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addhuodong(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 添加 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deletes(scope.$index, scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.Cities },
                          model: {
                            value: _vm.checkedCities,
                            callback: function ($$v) {
                              _vm.checkedCities = $$v
                            },
                            expression: "checkedCities",
                          },
                        },
                        _vm._l(_vm.cities, function (city) {
                          return _c(
                            "el-checkbox",
                            { key: city.id, attrs: { label: city.id } },
                            [_vm._v(" " + _vm._s(city.name) + " ")]
                          )
                        }),
                        1
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.form.check_status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "check_status", $$v)
                            },
                            expression: "form.check_status",
                          },
                        },
                        [
                          _vm._v(
                            " 必须审核：所有预存款协议，必须后台审核后方可生效 "
                          ),
                        ]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.form.check_status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "check_status", $$v)
                            },
                            expression: "form.check_status",
                          },
                        },
                        [
                          _vm._v(
                            " 异常审核：勾选后，业务员修改协议内容，需后台审核后方可生效 "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("span", [_vm._v("赠品赠送条件：")]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.gift_goods_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "gift_goods_type", $$v)
                        },
                        expression: "form.gift_goods_type",
                      },
                    },
                    _vm._l(_vm.citiesing, function (city) {
                      return _c(
                        "el-radio",
                        { key: city.id, attrs: { label: city.id } },
                        [_vm._v(" " + _vm._s(city.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.createtabl } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }